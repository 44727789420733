import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "key": "reusable",
  "langKey": "en",
  "title": "PrivacyPolicy-1"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Maintenance Connection Canada Privacy Policy`}</h1>
    <p>{`Maintenance Connection Canada is committed to safeguarding your privacy online. Protecting your personal information and using it only in ways to which you have consented is of the utmost importance to us. We have prepared this Privacy Policy to let you know how we will and will not use the personal information that you entrust to us.`}</p>
    <h2>{`Information We Collect From You and How We Use It`}</h2>
    <p>{`The information you give to Maintenance Connection Canada from any of our web sites is used to provide you with personalized content tailored to your particular interests, to allow you to locate and purchase products from vendors who have chosen to make their product information available via our website, to allow you to post and bid on RFQs, and to continually improve the convenience and functionality of this Site for our customers.`}</p>
    <p>{`In general, you can visit maintenanceconnection.ca on the World Wide Web without telling us who you are or providing us with any information. Under many circumstances, you will be asked to register and provide demographic, personal and commercial information. We collect the domain names (not the e-mail addresses) of all visitors to our Site to measure the number of visits, pages visited, average time spent on the Site, etc. We also collect the e-mail addresses of those who send us e-mail messages, but we will never share those addresses with any other company other than the dealers of our software such as Maintenance Connection, Inc.`}</p>
    <p>{`When you provide us with personal or commercial information, you are agreeing to share that information with Maintenance Connection Canada and its affiliates. When you provide us with such information (for example, your name, company name, address, phone number, credit card information, interests and other personal data), we will often let you know at the time of collection how we will use this information. For example, we may use information you have given us to provide a personalized service, ensure the proper delivery and billing of orders, inform you about our products and services, or measure the interest of visitors like yourself in the features of our products and websites.`}</p>
    <p>{`We may tailor our offers to you based on information you provide when you purchase products and services from us, from your responses to our online surveys, from information and behavior that may indicate your preferences, as well as information available from external sources.`}</p>
    <p>{`We may also conduct online surveys to better understand the needs and profiles of visitors to our websites. When we conduct such a survey, we will let you know how we will use your responses at the time we collect them from you. Your participation in any of our surveys is optional. You are not required to respond to a survey in order to visit our Sites or take advantage of any of their features.`}</p>
    <p>{`When you place an order through any of our Sites, we will collect the following information to properly process your order: your name, company name, address, phone number, e-mail address, shipping information and credit card information. We will share this information with the vendors of the goods or services which you have ordered through the particular Site. We will use reputable companies to verify your credit card information and credit worthiness before billing your account. Where applicable, we will also share your shipping information with the delivery service you request. `}</p>
    <h2>{`Information That You Submit About Other People`}</h2>
    <p>{`On some of our website pages, you can submit information about other people and compose personalized messages to them. For example, you can send a copy of an article to a friend along with a personalized message you compose for the recipient. We consider the information you submit to us and contents of your personalized messages to be private communications between you and the recipient.`}</p>
    <p>{`Maintenance Connection Canada will not disclose any information you provide about someone else, except that we may do so: to ship an order and confirm delivery, to identify or resolve technical problems or customer service issues, as required by law, to comply with a court order or other legal process, to enforce the Terms of Use, to respond to claims that your personalized messages violate the rights of third parties, and to protect the rights or property of Maintenance Connection Canada and its affiliates.`}</p>
    <h2>{`Sharing Information`}</h2>
    <p>{`Unless you specifically grant us permission, we will not trade, rent or sell your personal information, your survey responses or any other specific details about you or your company. We may provide aggregate statistics to reputable parties about the visitors to our Sites, but these statistics will not include any personal or private information that identifies you.`}</p>
    <p>{`Please note that Maintenance Connection Canada and its affiliates will fully cooperate with law enforcement and other governmental agencies to identify those individuals who use our Sites for fraudulent or other illegal activities.`}</p>
    <h2>{`Security`}</h2>
    <p>{`To provide you with an increased level of security, access to your personal information may be protected with a password you select. We strongly recommend that you do not disclose your password to anyone. We will never ask you for your password in any unsolicited communication (including unsolicited correspondence such as letters, phone calls or e-mail messages).`}</p>
    <p>{`Since any order information you provide to us will be transmitted using a secure connection, if your Web browser cannot support this level of security, you will not be able to order products through our websites. The most recent versions of popular browsers can support a secure connection and can be downloaded for free from their respective sites.`}</p>
    <p>{`Although no data transmission over the Internet can be guaranteed to be 100% secure, we will do our best to ensure the security of your information on our systems. As a result, while we strive to protect your information, Maintenance Connection Canada and its affiliates cannot ensure or warrant the security of any information you transmit to us.`}</p>
    <h2>{`Cookies`}</h2>
    <p>{`We may use a technology called "cookies" to provide you with personalized information. A cookie is an element of data that our Sites can send to your browser, which may then be stored on your hard drive so we can recognize you when you return. We will not store passwords or any personally identifying information in our cookies, nor will we read cookies created by other web sites except as required by the Maintenance Connection LoginHub for purposes of communicating with your login provider. All pages on our websites where you are prompted to sign on or that are customizable require that you accept cookies. These cookies will let us know who you are and are necessary to access your account information (stored on our computers) in order to deliver products and personalized services. You may set your Web browser to notify you when you receive a cookie. However, should you decide not to accept cookies from us, you will limit the functionality we can provide when you visit our Site.`}</p>
    <h2>{`Links to Other Sites`}</h2>
    <p>{`Our Site provides links to external sites as a convenience to the user. The inclusion of any link does not imply our endorsement of any other company, its site, or the products and services it sells. We are not responsible for the privacy practices or the content of any other Web site. In addition, it is intended that we transmit your personal information to third parties, such as the vendors and suppliers of goods and services. We cannot control, and are not responsible for, the actions of third parties to whom your personal and commercial information may be transmitted. If any such third party misuses, misappropriates or publishes your personal or commercial information, we are not responsible and do not accept any liability. If notified by you, as our sole obligation and your sole recourse, we will take reasonable commercial measures within our control to mitigate the problem and minimize the probability of its recurrence.`}</p>
    <h2>{`Children`}</h2>
    <p>{`The online privacy and safety of children is important to Maintenance Connection Canada. We provide products and services for businesses, government agencies, and other organizations. We will never encourage children to use the Site, nor will we ever knowingly market our products or services to children.`}</p>
    <h2>{`Correcting/Updating Personal Information`}</h2>
    <p>{`You can access your personal information at anytime to update, correct, add or delete any of the data you have shared with us. To access your personal information, click on the location you log in to access our services, then click on your picture or "My Profile" at the top or bottom to go your personal profile page.`}</p>
    <h2>{`Problems & Liability`}</h2>
    <p>{`If we are notified by you that you object to the manner in which we have used or cared for your personal or commercial information, or if your personal or commercial information is misused, misappropriated or published by us, then we will take reasonable commercial measures within our control to verify and mitigate the problem, and minimize the probability of its recurrence. This is our sole obligation, and your sole recourse, in such event. Maintenance Connection Canada AND ITS OFFICERS, DIRECTORS, EMPLOYEES, SUBSIDIARIES AND AFFILIATES SHALL HAVE NO LIABILITY ARISING IN CONNECTION WITH OUR USE OR HANDLING OF YOUR INFORMATION FOR ANY INDIRECT, SPECIAL OR CONSEQUENTIAL DAMAGES, OR FOR LOST OR CORRUPTED DATA OR INFORMATION. OUR LIABILITY FOR ANY CLAIMS SHALL NOT EXCEED $10,000 Canadian IN THE AGGREGATE.`}</p>
    <h2>{`Your Responsibilities`}</h2>
    <p>{`Your information and the purchase or sale of items through the our websites:
(a) shall not violate any law, statute, ordinance or regulation (including without limitation those governing export control, dealer agreements, unfair competition, anti-discrimination or false advertising);
(b) shall not be defamatory, trade libelous, unlawfully harassing, threatening, abusive, hateful or obscene;
(c) shall not impair the privacy of communications;
(d) shall not constitute or encourage conduct that would constitute a criminal offense or give rise to civil liability;
(e) shall not cause technical disturbances to our websites or network, including, but not limited to, introduction of viruses, worms, or other destructive mechanisms;
(f) shall not constitute deceptive on-line marketing. If you become aware of any such activities, you should immediately notify us and take all appropriate actions to cause such activities to cease. We reserves the right to take any such action with respect to such information that we deem necessary or appropriate in our sole discretion if we believes it may create liability for us, including the suspension of your access to our websites and network.`}</p>
    <h2>{`Contact Us`}</h2>
    <p>{`We want to assure you that we are dedicated to protecting your privacy. If you have any questions or comments about this Privacy Policy, do not want to us to share your information with others, or do not want us to send you information about products and services, please write us at:`}</p>
    <p>{`Maintenance Connection Canada
Box 516
Crossfield Alberta T0M 0S0
`}<a parentName="p" {...{
        "href": "mailto:info@maintenanceconnection.ca"
      }}>{`info@maintenanceconnection.ca`}</a></p>
    <h2>{`Changes to the Privacy Policy`}</h2>
    <p>{`Your use of this Site constitutes your consent to our collection and use of the information described in this Privacy Policy, and subject to the terms and conditions stated above. If we change our Privacy Policy, we will update this page of the Site so you will always be aware of how we use your information. The amended terms shall be automatically effective 30 days after they are posted on the `}<a parentName="p" {...{
        "href": "http://www.maintenanceconnection.ca"
      }}>{`http://www.maintenanceconnection.ca`}</a>{` website. The terms and conditions may otherwise be amended only by a writing signed by both parties. Your continued use of this site or the services will constitute your acceptance of any such changes.`}</p>
    <p>{`Effective date: 2022.11.0`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      